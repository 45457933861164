// ugh, sorry for all the !important - struggling to find a better way!
.swal2-popup {
    border-radius: 0 !important;
    padding: 0 !important;

    .swal2-header {
        padding: 1.5rem !important;
        padding-bottom: 0 !important;
    }

    .swal2-content {
        padding: 0 1.5rem !important;
    }

    .swal2-actions {
        justify-content: space-between !important;
        width: 100% !important;
        padding: 1.5rem !important;
        border-top: 1px solid $gray-400 !important;
    }

    .swal2-title {
        font-size: 1.5rem !important;
        font-weight: 400 !important;
    }
}