@font-face {
    font-family: Gotham;
    src: url('../fonts/Gotham/Gotham Normal.eot');
    src: url('../fonts/Gotham/Gotham Normal.eot?#iefix') format("embedded-opentype"),
         url('../fonts/Gotham/Gotham Normal.woff2') format("woff2"),
         url('../fonts/Gotham/Gotham Normal.woff') format("woff"),
         url('../fonts/Gotham/Gotham Normal.ttf') format("truetype"),
         url('../fonts/Gotham/Gotham Normal.svg#GothamNormal') format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Gotham;
    src: url('../fonts/Gotham/Gotham Bold.eot');
    src: url('../fonts/Gotham/Gotham Bold.eot?#iefix') format("embedded-opentype"),
         url('../fonts/Gotham/Gotham Bold.woff2') format("woff2"),
         url('../fonts/Gotham/Gotham Bold.woff') format("woff"),
         url('../fonts/Gotham/Gotham Bold.ttf') format("truetype"),
         url('../fonts/Gotham/Gotham Bold.svg#GothamBold') format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: NeueHaasUnicaW01;
    src: url(../fonts/psa/NeueHaasUnicaW01-Regular.eot);
    src: url(../fonts/psa/NeueHaasUnicaW01-Regular.eot?#iefix) format("embedded-opentype"),
         url(../fonts/psa/NeueHaasUnicaW01-Regular.woff2) format("woff2"),
         url(../fonts/psa/NeueHaasUnicaW01-Regular.woff) format("woff"),
         url(../fonts/psa/NeueHaasUnicaW01-Regular.ttf) format("truetype"),
         url(../fonts/psa/NeueHaasUnicaW01-Regular.svg#NeueHaasUnicaW01Regular) format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: NeueHaasUnicaW01;
    src: url(../fonts/psa/NeueHaasUnicaW01-Bold.eot);
    src: url(../fonts/psa/NeueHaasUnicaW01-Bold.eot?#iefix) format("embedded-opentype"),
         url(../fonts/psa/NeueHaasUnicaW01-Bold.woff2) format("woff2"),
         url(../fonts/psa/NeueHaasUnicaW01-Bold.woff) format("woff"),
         url(../fonts/psa/NeueHaasUnicaW01-Bold.ttf) format("truetype"),
         url(../fonts/psa/NeueHaasUnicaW01-Bold.svg#NeueHaasUnicaW01Bold) format("svg");
    font-weight: 700;
    font-style: normal;
}