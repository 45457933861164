body {
    overflow-x: hidden;
    margin: 0;
    position: relative;
    padding-bottom: 150px;
    min-height: 100vh;
    background-color: $stellantis-gray;
    font-family: $font-family-heading;
    letter-spacing: .01em;
}

header {
    background-color: $white;
    padding: 1rem;
        height:82px;
        vertical-align:middle;
    @include media-breakpoint-up(md) {
        /* padding: 1.5rem; */
    }

    .container, .container-fluid {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    a.logo {
        svg {
            width: 200px;
            height: 40px;
            @include media-breakpoint-up(md) {
                width: 236px;
                height: 50px;
            }
            fill: $stellantis-blue;
        }
    }

    h1 {
        margin-left: 1.5rem;
        margin-bottom: 0;
    /*  text-transform: uppercase;  */
        font-size: 1.2rem;
        flex-grow: 1;
        display: none;
        @include media-breakpoint-up(sm) {
            display: block;
        }
        @include media-breakpoint-up(md) {
            font-size: 1.8rem;
            white-space: nowrap;
        }
    }

    nav {
        flex-grow: 2;
        @include media-breakpoint-down(lg) {
            display: none;
        }

        > ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: flex-end;

            > li {
                margin-right: 1.5rem;
                position: relative;

                > a {
                    opacity: 1;
                    transition: opacity 0.3s;
                    color: $stellantis-blue;
                    &.open {
                        opacity: 1 !important;
                    }

                    &.active::before {
                        display: block;
                        content: ' ';
                        border-bottom: 1px solid $psa-red;
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        top: 35px;
                    }
                }

                > ul {
                    display: none;
                    list-style-type: none;
                    position: absolute;
                    padding: 30px 0 0 0;
                    margin: 0;
                    z-index: 9999999;
                    white-space: nowrap;
                    top: 18px;
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;

                    > li {
                        background: $white;
                        line-height: 2rem;
                        padding: 0 2rem;
                        box-shadow: 3px 3px 4px $gray-400;

                        &:first-of-type {
                            padding-top: 2rem;
                            border-top: 1px solid $psa-red;
                        }

                        &:last-of-type {
                            padding-bottom: 2rem;
                        }

                        > a {
                           /* transition: opacity 0.3s; */
                            color: $stellantis-black;
                            &:hover {
                                color: $stellantis-black;
                                font-weight: bold;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            &:hover > li > a {
                opacity: 0.4;

                &:hover {
                    opacity: 1;
                }
            }
        }

        form[role=search] {
            height: 26px;
            input {
                display: none;
            }

            button {
                height: 26px;
                width: 26px;
                background: transparent;
                border: none;

                svg {
                    fill: $stellantis-blue;
                    width: 17px;
                    margin-top: -4px;
                }
            }
        }
    }

#nav-button {
    background-image: url(../images/menu-open-in.png);
    height: 25px;
    width: 25px;
    background-size: contain;
    background-repeat: no-repeat;
}

    button.burger {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        margin-top: -3px;

        &:focus {
            outline: none;
        }

        span {
            color: $stellantis-blue;
            text-transform: uppercase;
            vertical-align: middle;
            font-size: 11px;
            font-family: $font-family-heading;
            top: 1px;
            position: relative;
        }
        svg {
            width: 26px;
            height: 20px;
            fill: $white;
            margin-left: 0.8rem;
            margin-top: 1px;
        }
    }
}

.menu-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $black;
    display: none;
    z-index: 10000;
    opacity: 0;
    transition: all 0.5s ease-in;

    .nav-drawer-open & {
        display: block;
        opacity: 0.8;
    }
}
                   
 .top-navigation > ul > li {
        border-right-width: 1px;
        border-color: $stellantis-blue;
        padding: 0 10px;
        text-transform: uppercase;
        font-size: 11px;
        height: 15px;
        color:#212529;
     }
    li.dropdown {
        postion: relative;
    }    
nav.sub-nav {
    background: $psa-blue;
    border-top: 1px solid $psa-gray;
    border-bottom: 1px solid $psa-gray;
    height: 4rem;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;

        li {
            margin-right: 1.5rem;
            transition: opacity 0.3s;
            position: relative;

            a {
                color: $stellantis-black;
                padding: 0;
                line-height: 4rem;

                &.active::before {
                    display: block;
                    content: ' ';
                    border-bottom: 1px solid $psa-red;
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    top: 50px;
                }
            }
        }

        &:hover > li {
             opacity: 0.4;

             &:hover {
                 opacity: 1;
             }
        }
    }
}

h2.page-title {
    margin: 2rem 0;
}

aside.drawer {
    background-color: $white;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 300px;
    transform: translateX(300px);
    opacity: 0;
    transition: all 0.5s ease-in;
    z-index: 20000;
    padding: $grid-gutter-width;
    overflow-y: auto;
    overflow-x: hidden;

    .nav-drawer-open & {
        transform: translateX(0);
        opacity: 1;
    }

    nav {

         ul {
             list-style-type: none;
             margin: 0;
             padding: 0;

             li {
                 transition: opacity 0.3s;
                 line-height: 2.5rem;
                 position: relative;
                 &.fixed {
                     margin-bottom: 1.5rem;
                 }
                 a {
                    color:  $stellantis-black;
                 }
                 a.active::before {
                     display: block;
                     content: ' ';
                     border-left: 2px solid $psa-red;
                     position: absolute;
                     height: 60%;
                     left: -29px;
                     top: 20%;
                 }
             }

             &:hover > li {
                 &:hover {
                     font-weight: bold;
                     text-decoration: underline;
                 }
             }

             ul {
                 display: none;
             }
         }

         form[role=search] {
             height: 26px;
             input {
                 border: none;
                 background-color: rgba(255,255,255,0.2);
                 color:  $stellantis-black;
                 padding: 0 0.5rem;
                 line-height: 2rem;
                 width: 200px;
              }

             button {
                 height: 32px;
                 width: 26px;
                 background: $psa-blue;
                 border: none;
                 position: relative;
                 top: 5px;
                 left: -4px;

                 svg {
                     fill: $psa-blue;
                     width: 17px;
                     position: relative;
                     top: -7px;
                     left: -2px;
                 }
                 &:hover {
                     background-color: $psa-blue;
                 }
             }
            
         }
    }

    .auth {
        border-top: 1px solid $gray-700;
        border-bottom: 1px solid $gray-700;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        color:  $stellantis-black;
        strong {
             color:  $stellantis-black;
            a {
                color:  $stellantis-black;
                &:hover {
                    color:  $stellantis-black;
                }
            }
        }
    }

    .nav-social {
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        margin: 0;
        padding: 0;
        padding-top: 1.5rem;

        li {
            margin-right: 2rem;
        }

        svg {
            fill: $white;
            &.icon-twitter {
                width: 22px;
                height: 18px;
            }
            &.icon-linkedin {
                width: 27px;
                height: 24px;
            }
            &.icon-facebook {
                width: 11px;
                height: 24px;
            }
            &.icon-youtube {
                width: 22px;
                height: 27px;
            }
        }
    }

    .side-sub-navigation {
        background-color: $white;
        position: absolute;
        top: 0;
        right: 0;
        min-height: 100vh;
        width: 300px;
        transform: translateX(300px);
        opacity: 0;
        transition: all 0.5s ease-in;
        z-index: 200;
        padding: $grid-gutter-width;

        &.show {
            transform: translateX(0);
            opacity: 1;
        }

        button {
            font-size: 12px;
            font-family: $font-family-heading;
            color: $stellantis-blue;
            text-transform: uppercase;border: none;
            background: transparent;
            line-height: 2.5rem;
            padding: 0;
            margin: 0;
            border-bottom: 1px solid $gray-700;
            width: 100%;
            text-align: left;
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;
            position: relative;
            padding-left: 30px;
            cursor: pointer;

            &:focus {
                outline: none;
            }

            &:before {
                content: ' ';
                display: block;
                width: 16px;
                height: 16px;
                background: url('../images/icons/back.svg') left top no-repeat;
                position: absolute;
                top: 12px;
                left: 0;
            }
        }
    }
}
main > .card {
    border: none;
    border-radius: 0;
    box-shadow: $gray-400 4px 4px 10px;
    margin: 2rem auto;
}

main.container-fluid {
    padding: 0  calc(1.5rem + 15px);
}

footer {
    background-color: $psa-dark-blue;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: $white;
    min-height: 50px;
    padding: 1rem 0;

    .footer-right {
        text-align: right;
    }
}
