@import 'fonts';

body{
    //
}

h1 {
    font-family: $font-family-heading;
    color: $stellantis-blue;
    font-size: 1.8rem;
    font-weight: 100; 
}

nav {
    ul {

        li {

            a {
                font-size: 11px;
                font-family: $font-family-heading;
                color: $stellantis-blue;
                text-transform: uppercase;

                &:hover {
                    color: $stellantis-blue;
                    text-decoration: none;
                }
            }
        }
    }
}