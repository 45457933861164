// Typography
$font-family-sans-serif: "NeueHaasUnicaW01", "Helvetica Neue", Helvetica, sans-serif !default;
$font-family-heading: 'Encode Sans Condensed',sans-serif !default;

// Corporate colours
$psa-blue: #243781;
$psa-red: #ff4238;
$psa-gray: #b2b2b2;
$psa-dark-blue:#282b34;

$stellantis-blue : #243882;
$stellantis-active-blue : #212529;
$stellantis-gray: #eff0ef;
$stellantis-black : #282b34;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     $psa-red !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$primary:       $psa-blue !default;
$secondary:     $psa-gray !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $psa-red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$body-bg: $light;