.dataTable {
    tr.odd:hover, tr.even:hover {
        td {
            background-color: $gray-200;

            input[type=text] {
                background-color: $gray-200;
            }
        }
    }

    td input {
        display: inline;
        box-shadow: none;
        border: none;
        border-bottom: 1px solid $psa-blue;
        border-radius: 0;
        padding-top: 0;
        padding-bottom: 0;
        height: 1.8rem;
        margin-right: 1rem;

        &:focus {
            box-shadow: none;
        }
    }

    td input + .btn {
        display: inline;
        height: 1.8rem;
        padding-top: 6px;
    }

    .single-action button {
        border : 1px solid lighten($psa-blue, 20%);
        background-color: $white;
        box-shadow: 1px 1px 4px $gray-200;
        padding: 0.1rem 1rem 0.1rem 0.5rem;
        width: auto;

        &:hover {
            cursor: pointer;
            background: lighten($psa-blue, 70%);
        }

        &:active {
            color: $gray-900;
            opacity: 0.7;
        }

        &:focus {
            outline: none;
        }
    }

    td.actions {
        padding: 0;
    }

    .datatable-quickview {
        text-align: center;

        i {
            display: block;
            padding: 0.7rem;
            cursor: pointer;
            color: $link-color;

            &:hover {
                color: $link-hover-color;
            }
        }
    }

    // 3 dot vertical menu button
    button.btn.btn-inline-menu {
        border: none;
        box-shadow: none;
        padding: 8px 12px;
        float: right;
        background-color: transparent;

        span {
            display: block;
            background-color: $gray-600;
            border-radius: 10px;
            width: 4px;
            height: 4px;
            position: relative;
            cursor: pointer;

            &:before {
                content: '';
                display: block;
                background-color: $gray-600;
                border-radius: 10px;
                width: 4px;
                height: 4px;
                position: absolute;
                top: -7px;
                cursor: pointer;
            }

            &:after {
                content: '';
                display: block;
                background-color: $gray-600;
                border-radius: 10px;
                width: 4px;
                height: 4px;
                position: absolute;
                top: 7px;
                cursor: pointer;
            }
        }
    }

    .dropdown-menu.show {
        // this should keep the context menu within the bounds of the table
        left: auto !important;
        right: 0 !important;
    }

    .dropdown-item {
        i {
            margin-right: 20px;
        }
    }
}

// Styling overrides
// Move the sorting arrows to the left
table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
    padding-left: 25px;
    padding-right: 0.5rem;

    &:before {
        left: 0;
        right: initial;
        bottom: 11px;
    }

    &:after {
        left: 0.5rem;
        right: initial;
        bottom: 11px;
    }
}

// Adjust the expand button
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
    top: 12px;
    left: 0px;
    height: 14px;
    width: 14px;
    display: block;
    position: absolute;
    color: navy;
    border: 2px solid white;
    border-radius: 14px;
    -webkit-box-shadow: 0 0 3px #444;
    box-shadow: 0 0 3px #444;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: 'Courier New', Courier, monospace;
    line-height: 14px;
    content: '>';
    background-color: transparent;
    font-weight: 900;
}

// Adjust the padding and positioning
.table th, .table td {
    padding: 0.7rem;
    padding-left: 25px;
    vertical-align: middle;
    font-size: 0.8rem;
}

// General table styles
.table tbody tr.selected {
    background-color: rgba(0, 0, 0, 0.075);
}

.dataTables_wrapper {
    width: 100%;
    .dataTables_length {
        float: left;
    }

    .dataTableBuilder_filter {
        float: right;
    }

    div.dataTables_filter input:focus {
        box-shadow: none;
        border-radius: 0;
    }
}

table.dataTable > tbody > tr.child ul.dtr-details {
    display: block;

    li {
        display: flex;
        padding: 0.8em 0;

        span.dtr-title {
            width: 250px;
            display: block;
        }
    }
}
