.modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    box-shadow: $gray-700 6px 6px 20px;
}

.modal-footer {
    justify-content: space-between;
}

.modal-dialog.modal-fluid {
    max-width: 2000px;
    width: 80%;
}