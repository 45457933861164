.filter {
    display: flex;
    border: 1px solid #eee;
    padding: 5px 20px 5px 20px;
    height: 43px;

    label {
        opacity: 0.6;
        font-style: italic;
        padding-right: 25px;
        line-height: 30px;
        margin: 0;
        white-space: pre;
    }

    select, input {
        border: none;
        border-bottom: 1px solid $gray-400;
        border-radius: 0;
        background: transparent;
        flex: 2;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    input[type=text] {
        padding-bottom: 0;
        height: 2.3rem;
    }

    input[type=text] + input.btn {
        flex: 1;
        padding: 0;
        position: relative;
        top: -5px;
        height: 41px;
        right: -19px;
    }

    .bootstrap-select {
        width: 100% !important;

        button.dropdown-toggle {
            padding-top: 0.275rem !important;
            height: 2.3rem !important;
        }
    }
}

