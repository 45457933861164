dl {
    dt {
        padding-bottom: 0.3rem;
        border-bottom: 1px solid $gray-400;

        &.key {
            padding-bottom: 0;
            border-bottom: none;
        }
    }

    dd {
        padding-left: 1rem;
        margin-top: 0.5rem;
        margin-bottom: 2rem;

        &.value {
            margin-top: 0;
        }
    }
}

ul.responsive-columns {
    list-style-type: none;
    margin: 0;
    font-size: 0.8rem;
    padding: $grid-gutter-width 0;
    @include media-breakpoint-up(md) {
        padding: $grid-gutter-width;
    }
    @include make-row();

    li {
        margin-bottom: $grid-gutter-width / 2;
        @include make-col-ready();@include make-col(12);
        @include media-breakpoint-up(sm) {
            @include make-col(7);
        }
        @include media-breakpoint-up(md) {
            @include make-col(8);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
    }
}

.key-value-pairs {
    font-size: 0.8rem;
    padding: $grid-gutter-width 0;
    @include media-breakpoint-up(md) {
        padding: $grid-gutter-width;
    }
    @include make-row();

    .key, .value {
        margin-bottom: $grid-gutter-width / 2;
        @include make-col-ready();
    }

    .key {
        font-weight: 700;
        @include make-col(12);
        @include media-breakpoint-up(sm) {
            @include make-col(5);
        }
        @include media-breakpoint-up(md) {
            @include make-col(4);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(2);
        }
    }

    .value {
        @include make-col(12);
        @include media-breakpoint-up(sm) {
            @include make-col(7);
        }
        @include media-breakpoint-up(md) {
            @include make-col(8);
        }
        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
    }
}
