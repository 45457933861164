.auth {
    header {
        h1 {
            text-align: right;
        }
    }

    .row.banner {
        margin: 10rem 0;
        align-items: center;
        background: $psa-blue url(/images/login-bg@2x.png) left top no-repeat;
        box-shadow: $gray-400 4px 4px 10px;

        .card.login-box {
            margin: -2rem 1rem -2rem 1rem;
            box-shadow: #aaa 0 5px 15px;
            border: none;
            border-radius: 0;

            h2 {
                text-transform: uppercase;
                font-family: $font-family-heading;
                text-align: center;
                margin: 0 0 2rem 0;
                padding: 1rem 0 2rem 0;
                border-bottom: 1px solid $gray-400;
            }

            .checkbox {
                margin-bottom: 2rem;
            }

            .login-box-footer {
                border-top: 1px solid $gray-400;
                background-color: $white;
                padding: 1.5rem;
            }
        }

        h1 {
            text-align: center;
            text-transform: uppercase;
            font-family: $font-family-heading;
            color: black;
        }
    }
}