#psa-branding {
    display: none;
    background-color: $white;
    border-top: 2px solid $psa-red;
    box-shadow: $gray-400 4px 4px 10px;
    padding: 5px 0;

    .container, .container-fluid {
        height: 40px;

        img {
            float: right;
            height: 40px;
        }
    }

    .container-fluid img {
        margin-right: 25px;
    }
}
.pmc, .cuk, .dsa , .ov {
    display: block !important;
}
