@import '_variables.scss';
@import '../../vendor/psagroupeuk/theme/resources/theme/psagroupe/sass/psa';
@import '~dropzone/dist/dropzone.css';
@import 'richtext.scss';

.input-group .form-control {
    padding-left:0px !important;
}

body {
    background-color: white !important;
    color: #1d1d1b;
}

h1 {
    color: #1d1d1b;
}

.btn {
    text-transform: none !important;
    background-color: $stallantis_blue;
}

.btn:hover {
    text-transform: none !important;
    background-color: $stallantis_blue;
}

@media all and (max-width: 767px) {

    .card-text {
        font-size: 13px !important;
    }

    .card-body h3 {
        font-size: 16px !important;
    }
}

.card-vauxhall {
    background-color: grey;
}

a:hover {
    color: #7e7e7f;
    text-decoration-color: $stallantis_blue;
}

footer {
    background-color: grey;
}

#nav-button {
    background-image: url("/images/menu.png") !important;
}

.badge-primary {
    background-color: $stallantis_blue;
}

.logo img {
    max-width: 280px;
    margin-right: 15px;
}
