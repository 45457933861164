.mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    z-index: 10000;

    // this is just to ensure the spinner sits vertically centered
    span {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }

    img {
        margin: auto;
        vertical-align: middle;
    }
}