.card {
    .card-header {
        background: $white;
        position: relative;

        .nav-tabs {
            position: relative;
            bottom: -0.75rem;
            border-bottom: none;
        }
    }

    .card-footer {
        display: flex;
        justify-content: space-between;
    }

    .card-title {
        margin-bottom: 1.5rem;
        font-size: 1.4rem;
        border-bottom: 1px solid $gray-400;
        padding-bottom: 0.5rem;
    }

    .card-subtitle {
        font-size: 1.3rem;
        border-bottom: 1px solid $gray-400;
        margin: 2rem 0
    }
}