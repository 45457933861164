.btn {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
    border-radius: 0;
    text-transform: uppercase;
    padding: 0.7rem 2rem;
    font-size: 0.8rem;
    background-color : $psa-blue;
    color : $white;
     &:hover {
        background-color : $psa-blue;
        color : $white;
     }
    &.btn-sm {
        font-size: 0.7rem;
        padding: 0.5rem 1rem;
    }

    &.btn-link {
        box-shadow: none;
    }

    &.btn-goto {
        box-shadow: none;
        padding: 0;
    }

    &.btn-icon-only {
        margin-left: 10px;
        padding: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-200;

        &:hover {
            background-color: $gray-400;
        }

        &.active {
            background-color: $gray-300;
        }

        i {
            font-size: 1.5rem;
        }
    }
}