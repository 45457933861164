.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
    background: transparent !important;
    border-bottom-color: $psa-blue;
}

.bootstrap-select button.dropdown-toggle {
    background: transparent;
    text-transform: none;
    font-size: 1rem;
    color: $gray-800;
    padding: 0.375rem 0.75rem;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid $gray-200;
    height: 2.75rem;

    td &, th & {
        font-size: 0.8rem;
        height: 2rem;
        padding-left: 0;
    }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
        border-bottom-color: $psa-blue;
    }

    &:hover {
        background: transparent;
        border-bottom-color: $psa-blue;
    }
}

input.form-control {
    border: none;
    border-bottom: 1px solid $gray-200;
    height: 2.75rem;
    font-size: 1rem;
}

.form-group .flatpickr-wrapper {
    display: block;

    &:after {
        right: 12px;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        position: absolute;
        top: 20px;
    }

    input.form-control:disabled, input.form-control[readonly] {
        background-color: transparent;
        border-radius: 0;

        &:focus {
            outline: none;
            box-shadow: none;
            border-bottom-color: $psa-blue;
        }

        &:hover {
            border-bottom-color: $psa-blue;
        }
    }
}

.input-group {
    margin-bottom: 1rem;
    .input-group-prepend {
        position: absolute;
        height: 2.7rem;
        z-index: 2;

        span {
            background: transparent;
            border: none;
            border-radius: 0;
            color: $psa-blue;
        }
    }

    .form-control {
        padding-left: 2.25rem;
    }
}

.help-block {
    display: block;
    width: 100%;
    margin: 0.5rem 0;
    color: $psa-red;
    font-weight: 300;
}

.invalid-feedback {
    font-size: 0.9rem;
}

.form-group.psa-material {
    // Material inputs
    position: relative;
    margin-top: 1.5rem;

    &:first-of-type {
        margin-top: 0;
    }

    &.is-invalid {
        label {
            color: $psa-red;
        }
    }

    label {
        color: $gray-600;
        font-weight: 200;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        z-index: 100;
        font-size: 1rem;

        &.float {
            top: -20px;
            font-size: 0.875rem;
            color: $gray-600;
        }
    }

    input.form-control {
        color: $black;
        font-weight: 200;
        padding: 10px 10px 10px 5px;
        display: block;
        border: none;
        border-bottom: 1px solid $gray-200;
        border-radius: 0;
        width: 100%;

        &.is-invalid {
            border-bottom-color: $psa-red;
        }

        &:read-only {
            background-color: unset !important;
            color: $gray-600 !important;
        }
    }

    input:focus {
        outline: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    /* active state */
    input:focus ~ label, input:valid ~ label,
        // datepicker uses a class for focus
    input.focus ~ label, .flatpickr-wrapper ~ label {
        top: -$grid-gutter-width / 2;
        font-size: 0.875rem;
        color: $gray-600;
    }

    /* BOTTOM BARS ================================= */
    .bar {
        position: relative;
        display: block;
        width: 100%;
    }

    .bar:before, .bar:after, .bootstrap-select .filter-option:before, .bootstrap-select .filter-option:after {
        content: '';
        height: 1px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: $psa-blue;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    .bootstrap-select .filter-option:before, .bootstrap-select .filter-option:after {
        bottom: -2px;
    }

    .bar:before, .bootstrap-select .filter-option:before {
        left: 50%;
    }

    .bar:after, .bootstrap-select .filter-option:after {
        right: 50%;
    }

    /* active state */
    input:focus ~ .bar:before, input:focus ~ .bar:after,
    .bootstrap-select.show button.btn-light .filter-option:before,
    .bootstrap-select.show button.btn-light .filter-option:after,
        // datepicker uses a class for focus
    input.focus ~ .bar:before,
    input.focus ~ .bar:after {
        width: 50%;
    }

    // bootstrap-select overrides
    &.select {

        label {
            top: 10px;
            color: $gray-600;

            &.float {
                top: -$grid-gutter-width / 2;
                font-size: 0.875rem;
                color: $gray-600;
            }
        }

        .dropdown {
            .dropdown-menu {
                padding: 0;
                width: 100%;

                li:first-child {
                    // @TODO: Figure out why this was here and if removing it causes other issues
                    // display: none;
                }

                .dropdown-item {
                    font-weight: 300;
                    padding: .5rem 1rem
                }
            }
        }

    }

    &.element_multi_site_storage.select .dropdown .dropdown-menu li:first-child {
        display: list-item;
    }


    .bootstrap-select {
        button.btn-light:not(:disabled):not(.disabled) {
            background: transparent;
            color: $black;
            font-weight: 200;
            padding: 10px 10px 10px 5px;
            display: block;
            border: none;
            border-bottom: 1px solid $gray-200;
            border-radius: 0;
            transition: border-color 0s, background-color 0s, box-shadow 0s;

            &:active {
                background-color: transparent;
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                color: $gray-600;
            }

            &:focus {
                -webkit-box-shadow: none;
                -moz-box-shadow: none;
                box-shadow: none;
                outline: none !important;
            }
        }

        &.show > .btn-light.dropdown-toggle {
            background-color: transparent;
        }
    }

    $md-checkbox-checked-color: $primary;
    $md-checkbox-border-color: rgba(0, 0, 0, 0.54);
    $md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
    $md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

    $md-checkbox-size: 1rem;
    $md-checkbox-padding: .25rem;
    $md-checkbox-border-width: 2px;
    $md-checkbox-border-radius: 0.125rem;
    $md-checkmark-width: 0.125rem;
    $md-checkmark-color: #fff;
    $md-checkbox-label-padding: .75rem;

    &.checkbox {
        position: relative;
        text-align: left;
        line-height: $md-checkbox-size;

        &.checkbox-inline {
            display: inline-block;
        }

        &.compact {
            margin: 0;

            label {
                font-size: 0.9rem !important;
                padding: 0 20px 0 30px;
                height: 2.1rem;

                &:not(:empty) {
                    padding: 0 20px 0 30px;
                }

                &:before, &:after {
                    top: 3px;
                }
            }
        }

        label {
            font-size: 1rem !important;
            display: inline-block;
            height: 2.75rem;
            line-height: 1.6;
            cursor: pointer;
            pointer-events: all;
            vertical-align: top;
            clear: both;
            padding: 10px 26px;
            top: 0 !important;
            position: relative;
            margin-bottom: 0;

            &:not(:empty) {
                padding: 10px 26px;
            }

            &:before, &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 14px;
            }

            &:before {
                // box
                width: $md-checkbox-size;
                height: $md-checkbox-size;
                background: #fff;
                border: $md-checkbox-border-width solid $md-checkbox-border-color;
                border-radius: $md-checkbox-border-radius;
                cursor: pointer;
                transition: background .3s;
            }

            &:after {
                // checkmark
            }
        }

        input[type="checkbox"] {
            outline: 0;
            visibility: hidden;
            width: $md-checkbox-size;
            margin: 0;
            display: block;
            float: left;
            font-size: inherit;
            position: absolute;

            &:checked {
                + label:before{
                    background: $md-checkbox-checked-color;
                    border:none;
                }
                + label:after {

                    $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

                    transform: translate($md-checkbox-padding, ($md-checkbox-size / 2) - ($md-checkmark-size / 2.6)) rotate(-45deg);
                    width: $md-checkmark-size;
                    height: $md-checkmark-size / 2;

                    border: $md-checkmark-width solid $md-checkmark-color;
                    border-top-style: none;
                    border-right-style: none;
                }
            }

            &:disabled {
                + label:before{
                    border-color: $md-checkbox-border-color-disabled;
                }
                &:checked {
                    + label:before{
                        background: $md-checkbox-checked-color-disabled;
                    }
                }
            }
        }
    }

    $md-radio-checked-color: $primary;
    $md-radio-border-color: rgba(0, 0, 0, 0.54);
    $md-radio-size: 16px;
    $md-radio-checked-size: 8px;
    $md-radio-ripple-size: 12px;

    @keyframes ripple {
        0% {
            box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.0);
        }
        50% {
            box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
        }
        100% {
            box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
        }
    }

    &.radio {
        line-height: $md-radio-size;

        &.radio-inline {
            display: inline-block;
            margin-top: 0.5rem;
            margin-bottom: 0;
        }

        input[type="radio"] {
            display: none;
            &:checked + label:before {
                border-color: $md-radio-checked-color;
                animation: ripple 0.2s linear forwards;
            }
            &:checked + label:after {
                transform: scale(1);
            }
        }

        label {
            font-size: 1rem !important;
            display: inline-block;
            height: $md-radio-size;
            line-height: $md-radio-size;
            pointer-events: all;
            position: relative;
            top: 0 !important;
            padding: 0 ($md-radio-size + 10px);
            margin-bottom: 0;
            cursor: pointer;
            vertical-align: bottom;
            &:before, &:after {
                position: absolute;
                content: '';
                border-radius: 50%;
                transition: all .3s ease;
                transition-property: transform, border-color;
            }
            &:before {
                left: 0;
                top: 0;
                width: $md-radio-size;
                height: $md-radio-size;
                border: 2px solid $md-radio-border-color;
            }
            &:after {
                top: $md-radio-size / 2 - $md-radio-checked-size / 2;
                left: $md-radio-size / 2 - $md-radio-checked-size / 2;
                width:$md-radio-checked-size;
                height:$md-radio-checked-size;
                transform: scale(0);
                background:$md-radio-checked-color;
            }
        }
    }

    td &.radio, td &.checkbox {
        margin: 0;

        label {
            color: $body-color;
            font-size: 0.8rem !important;
            font-weight: 400;
            padding: 0 30px !important;
            top: 0 !important;
            height: auto;
            min-height: 16px;

            &:before {
                top: 0;
                width: 16px;
                height: 16px;
            }

            &:after {
                top: 0px;
                left: 0px;
            }
        }
    }

    td &.radio {
        label:after {
            top: 4px;
            left: 4px;
        }
    }

    &.textarea {
        label {
            padding-left: 8px;
            top: 8px;
            border-bottom: 1px solid $gray-300;
            width: 80%;
        }

        textarea {
            border: none;
            border-left: 1px solid $gray-300;
            border-bottom: 1px solid $gray-400;
            padding-top: 40px;
            border-radius: 0;

            &:focus {
                box-shadow: none;
            }
        }
    }
}

// adjustments for error feedback
.is-invalid.select .form-control ~ .invalid-feedback,
.is-invalid.select .form-control ~ .invalid-tooltip {
    display: block;
    margin-top: 0.5rem;
}

.form-group.psa-material.is-invalid ~ .invalid-feedback,
.flatpickr-wrapper.is-invalid ~ .invalid-feedback {
    display: block;
}